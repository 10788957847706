

<template>
    <div class="pecwrapper tooltip">
        <span class="tooltiptext">{{experience.title}} {{ experience.id }} - {{ expdata }}</span>
        <div class="pecscore">{{ score }}</div>
        <div class="pec" @click="showTeachBack" :data-tb="experience.id" :style="experience.imgurl">
    </div>

    </div>
    
    </template>
    
    <script>
    
    export default {
        name: 'PatientExperienceCard',
      // Properties returned from data() become reactive state
      // and will be exposed on `this`.
      data() {
        return {
    
        }
      },
      props: ['expdata','score'],
      // Methods are functions that mutate state and trigger updates.
      // They can be bound as event handlers in templates.
      methods: {
    
        openCustomizeExperience(e) {
          //  console.log(e)
     var exp = e.currentTarget.dataset.exp;
          //emitter.emit('event-name',exp)
          this.$root.$emit('openModal',exp)
        },
        showTeachBack(e) {
          var tb = e.currentTarget.dataset.expid;
          this.$root.$emit('showTeachBack',tb);

        }
    
    
      },
    
      // Lifecycle hooks are called at different stages
      // of a component's lifecycle.
      // This function will be called when the component is mounted.
      mounted() {
        
    
    
      },
    
      created() {
     
    
    },
    computed: {
        
      experience() {
        var exp = this.$store.state.experiences.filter( exp => exp.id.includes(this.expdata))[0];
        return exp;
      }
    }
    }
    
    </script>
    
    
    <style lang="scss">
    
     .pec {
        width:50px;
        height:50px;
        border-radius:25px;
        background-size:50px 50px;
        margin:5px;
        box-shadow:4px 4px 4px rgba(0,0,0,0.5);
     }
     .pecscore {
        position:absolute;
           
        width:20px;
        height:20px;
        background-color:rgb(198, 0, 0);
        box-shadow:4px 4px 4px rgba(0,0,0,0.5);
        border-radius:10px;
        font-size:8px;
        font-weight:900;
        line-height:20px;
        text-align:center;
        color:white;
     }
    </style>
    