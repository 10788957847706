<template>
    <div class="login">
      <form @submit.prevent="processlogin">
        <div class="loginsec">
          <span class="label">email</span> 
          <input type="text" name="email"/>
        </div>
        <div class="loginsec">
          <span class="label">password</span>
          <input type="password" name="password"/>
        </div>
        <div class="loginsec">
          <div></div>
          <button class="signinbutton" type="submit">Sign In</button>
  
   <div>{{this.$store.state.currentUser}}</div>
   
  
        </div>
      </form>
    </div>
  </template>

  

<script>

export default {
  // Properties returned from data() become reactive state
  // and will be exposed on `this`.
  data() {
    return {
 
    }
  },

  // Methods are functions that mutate state and trigger updates.
  // They can be bound as event handlers in templates.
  methods: {

 
    processlogin(e) {

      var email = e.target.elements.email.value;
      var pass = e.target.elements.password.value;

      
 

      if(email.toLowerCase() == 'admin@hospital.com' && pass == 'admin') {
        this.$store.commit('changeCurrentUser','Admin')
        this.$store.commit('logInUser');
      }

      else if(email.toLowerCase() == 'hcpo@hospital.com' && pass == 'hcpo') {
        this.$store.commit('changeCurrentUser','HealthcareProvider')
        this.$store.commit('logInUser');
      }
      else if(email.toLowerCase() == 'ma@hospital.com' && pass == 'ma') {
        this.$store.commit('changeCurrentUser','MedicalAssistant')
        this.$store.commit('logInUser');
      }
      else {
        this.$store.commit('changeCurrentUser','Guest')
        this.$store.commit('logInUser');
      }


    this.$router.push('/home');
        }



  },

  // Lifecycle hooks are called at different stages
  // of a component's lifecycle.
  // This function will be called when the component is mounted.
  mounted() {
    if(this.$store.state.loggedIn) {
        this.$router.push('/home')
    }

  }
}

</script>



<style lang="scss">

 

.login {
 
  max-width:300px;
  margin:0 auto;


  input {
    width:200px;
    height:24px;
    margin:5px;
  }
  button {
    width:200px;
    margin:5px;
  }

}
.loginsec {
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
</style>