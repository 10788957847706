<template>
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <button class="modal-default-button" @click="$emit('close')">Close</button>
                <div class="modal-title">teachback</div>
                
                
              </div>
              <div class="modal-body patient-teachback-list">
                <TeachBackModule v-for="tb in this.$store.state.teachbackdata" :key="tb.id" :teachbackdata="tb"/>
    
              </div>
              <div class="modal-footer">
                
                <br/>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
    
    
    <script>
    import TeachBackModule from '@/components/TeachBackModule.vue'
    export default {
      // Properties returned from data() become reactive state
      // and will be exposed on `this`.
      data() {
 
        return {

          patient:null,
          newItem:{}
        }
      },

      components: {
    TeachBackModule
},


      props: ['teachbackdata'],
      // Methods are functions that mutate state and trigger updates.
      // They can be bound as event handlers in templates.
      methods: {
    
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
    return result;
},


      },
      mounted() {
        console.log(this.teachbackdata);
      },
    
      created() {
     
    },
    }
    
    </script>
    
    
    <style scoped lang="scss">
  $buttoncolor: #55ade1;
  $buttonhovercolor:#4188b1;


.patient-teachback-list {
    color:black;
}


    
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      display: table;
      transition: opacity 0.3s ease;
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    
    .modal-container {
      width: 500px;
      margin: 0px auto;
      padding: 10px 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      text-align:left;
    }
    
    .modal-header {
      font-size:12px;

 
    }
    
    
    .modal-header .modal-title {
      margin-top: 0;
      font-size:23px;
      font-weight:700;
      color:black;
    }
    
    
    
    
    .modal-body {
      font-size:16px;
      margin: 20px 0;
      line-height:2em;
    
    
    h2 {
      font-size:20px;
      font-weight:700;
      margin:10px 0px 20px 0px;
    }
    h3 {
      font-weight:700;
    }
    }
    
    .modal-footer {
      font-size:12px;
    min-height:50px;
    }
    
    
    .modal-default-button {
    float:right;
    background-color:#55ade1;
      margin:0px 10px;
      padding:5px 0px;
      border:0px;
      color:white;
      border-radius:5px;
      width:75px;
      &:hover {
        cursor:pointer;
        filter:brightness(1.1);
      }
    }
    
    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */
    
    .modal-enter {
      opacity: 0;
    }
    
    .modal-leave-active {
      opacity: 0;
    }
    
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    
    
    
    
    </style>