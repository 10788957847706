<template>
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <button class="modal-default-button" @click="$emit('close')">Close</button>
                <div class="modal-title">Add Experience</div>
                
                
              </div>
              <div class="modal-body">
                <h3>Patient:</h3>
                <div class="patientname"><span>Name:</span> {{ patientdata.lastname }} , {{ patientdata.firstname }} </div>
                <div class="patientname"><span>DOB:</span> {{ patientdata.dateofbirth }} </div>
                <div class="patientname"><span>Preferred Language:</span> {{ patientdata.preferred_language }} </div>
 

                <div class="experience-list-item" v-for="el in this.$store.state.experiences" :key="el.id">
                  <div class="eli-img" :style="el.imgurl"> </div>
                <div class="eli-title">{{ el.title }}</div>

                <button class="exp-select" @click="addThisExperience" :data-exp="el.id">Select</button>


              </div>

    

    
              </div>
              <div class="modal-footer">
                
                <br/>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </template>
    
    
    <script>
    
    export default {
      // Properties returned from data() become reactive state
      // and will be exposed on `this`.
      data() {
        return {

          patient:null,
          newItem:{}
        }
      },
      props: ['patientdata'],
      // Methods are functions that mutate state and trigger updates.
      // They can be bound as event handlers in templates.
      methods: {
    
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
    return result;
},



addThisExperience(e) {

 console.log(e);

  var pid = e.target.dataset.exp;
  console.log(pid);
  
  var date = new Date();
   var expdate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();

  this.newItem = {'id':this.makeid(5),'userid':this.patientdata.id,'experienceid':pid,'dateexperienced':expdate,'score':'0/3'};

      console.log(JSON.stringify(this.newItem));


      this.$store.dispatch('addExperience',this.newItem)
    this.$emit('close');

    console.log(this.$store.state.patientexperiences);
    }
    
    
      },
    
      // Lifecycle hooks are called at different stages
      // of a component's lifecycle.
      // This function will be called when the component is mounted.
      mounted() {
        console.log(this.patientdata);
      },
    
      created() {
     
    
    },
    }
    
    </script>
    
    
    <style lang="scss">
  $buttoncolor: #55ade1;
  $buttonhovercolor:#4188b1;
.patientname {
  font-size:16px;
  span {
    font-weight:700;
  }
}

.experience-list-item {
  border:1px solid #e1e1e1;
  border-radius:10px;
  margin-bottom:5px;
  padding:5px;
  display:flex;
  justify-content: space-between;
 
}
.exp-select {
  background-color:$buttoncolor;
  border:0px;
  border-radius:10px;
  color:white;
  &:hover {
    background-color: $buttonhovercolor;
  }
}
.eli-title {
  font-size:18px;
  font-weight:700;
}
.eli-img {
  width:50px;
  height:50px;
  border-radius:25px;
  background-size:50px 50px;
  margin-right:20px;
  
}
     .addpatientform {
      div {
        margin:20px;
      }
      input {
        height:35px;
        width:200px;
        padding:10px;
      }
      label {
        width:100px;
        display:inline-block;
      }
      select {
        width:100px;
        height:35px;
        padding:5px;
      }
      .addpatientbutton {
        background-color: #55ade1;
        border:0px;
        border-radius:5px;
        width:100px;
        height:35px;
        color:white;
      }
     } 
    
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      display: table;
      transition: opacity 0.3s ease;
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    
    .modal-container {
      width: 500px;
      margin: 0px auto;
      padding: 10px 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      text-align:left;
    }
    
    .modal-header {
      font-size:12px;
    }
    
    
    .modal-header .modal-title {
      margin-top: 0;
      font-size:23px;
      font-weight:700;
    }
    
    
    
    
    .modal-body {
      font-size:16px;
      margin: 20px 0;
      line-height:2em;
    
    
    h2 {
      font-size:20px;
      font-weight:700;
      margin:10px 0px 20px 0px;
    }
    h3 {
      font-weight:700;
    }
    }
    
    .modal-footer {
      font-size:12px;
    min-height:50px;
    }
    
    
    .modal-default-button {
    float:right;
    background-color:#55ade1;
      margin:0px 10px;
      padding:5px 0px;
      border:0px;
      color:white;
      border-radius:5px;
      width:75px;
      &:hover {
        cursor:pointer;
        filter:brightness(1.1);
      }
    }
    
    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */
    
    .modal-enter {
      opacity: 0;
    }
    
    .modal-leave-active {
      opacity: 0;
    }
    
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    
    
    
    
    </style>