

<template>
    <div class="patient" :data-id="patientdata.id" >


      <div>
        <div><span class="pspan">Last Name:</span> {{ patientdata.lastname }}</div> 
      <div><span class="pspan">First Name:</span> {{ patientdata.firstname }}</div>  
      <div><span class="pspan">DOB:</span> {{ patientdata.dateofbirth }}</div> 
        
      <div><span class="pspan">Preferred Language:</span> {{ patientdata.preferred_language }}</div> 
      </div>




      <div class="patientexperienceslist">
        <span class="pspan"><button @click="openCustomizeExperience" :data-patientid="patientdata.id">+</button>&nbsp;&nbsp;</span>
        <span class="pspan">Experiences: &nbsp;&nbsp;</span>
        <div  v-for="exp in this.patientExperiences" :key="exp.id" >
          <PatientExperienceCard :expdata="exp.experienceid" :score="exp.score" />
        </div>
      </div>


    </div>
    
    </template>
    
    <script>
    import PatientExperienceCard from '@/components/PatientExperienceCard.vue'

    export default {
      name: 'PatientList',
      components: {
        PatientExperienceCard
      },
      // Properties returned from data() become reactive state
      // and will be exposed on `this`.
      data() {
        return {
    
        }
      },
      props: ['patientdata'],
      // Methods are functions that mutate state and trigger updates.
      // They can be bound as event handlers in templates.
      methods: {
    
 
        openCustomizeExperience(e) {
          var exp = e.currentTarget.dataset.patientid;
          this.$root.$emit('addExperience',exp)
    }
    
      },
    
      // Lifecycle hooks are called at different stages
      // of a component's lifecycle.
      // This function will be called when the component is mounted.
      mounted() {
        
    
    
      },
    
      created() {
     
    
    },
    computed: {

      patientExperiences() {
        if(this.patientdata.id !='') {
          return this.$store.state.patientexperiences.filter( exp => exp.userid.includes(this.patientdata.id))
        }
        else {
          return this.$store.state.patientexperiences;
        }
      },

      experience() {
        if(this.patientdata.id !='') {
          return this.$store.state.patientexperiences.filter( exp => exp.userid.includes(this.patientdata.id))
        }
        else {
          return this.$store.state.patientexperiences;
        }
      }


    }
    }
    
    </script>
    
    
    <style lang="scss">

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top:-40px;
  left:20px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}




    .patient {
        padding:5px;
        border:1px solid #e1e1e1;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius:5px;
        margin:10px 0px;

    }
    .pspan {
        font-weight:700;
    }
    .patientexperienceslist {
      display:flex;
      flex-direction:row;
    }
    </style>
    