<template>
    <div class="browse-experiences">
      <modal-view v-if="showModal" @close="showModal = false" :modaldata="experienceData"/>
      <h1>Browse All Experiences</h1>
      <div v-for="section in this.$store.state.sections" :key="section.id" >
      <h2>{{ section.title }}</h2>
      
      <div class="sec-specialties" v-if="section.keyword == 'specialties'">
        <div @click="ShowSelectedModules" class="sec-item" v-for="specialty in  testaccess.specialties" :key="specialty.id" data-sec="specialties" :data-experiences="specialty.experiences">
           {{ specialty.title }} 
        </div>
      </div>

      <div class="sec-conditions" v-if="section.keyword == 'conditions'">
        <div @click="ShowSelectedModules" class="sec-item" v-for="condition in  testaccess.conditions" :key="condition.id" data-sec="conditions" :data-experiences="condition.experiences">
          {{ condition.title }}
        </div>
      </div>

      <div  class="sec-medications" v-if="section.keyword == 'medications'">
        <div @click="ShowSelectedModules" class="sec-item" v-for="medication in testaccess.medications" :key="medication.id" data-sec="medications" :data-experiences="medication.experiences">
          {{ medication.title }}
        </div>
      </div>

      <div class="sec-procedures" v-if="section.keyword == 'procedures'">
        <div @click="ShowSelectedModules" class="sec-item" v-for="procedure in testaccess.procedures" :key="procedure.id" data-sec="procedures" :data-experiences="procedure.experiences">
          {{ procedure.title }}
        </div>
      </div>


      </div>

 

      <div class="tempdiv">
        <FavoriteExperience v-for="experience in shownFilteredConditions" :key="experience.id" :expdata="experience"/>
      </div>
      
    </div>
</template>


<script>
// @ is an alias to /src
import ModalView from '@/components/ModalView.vue';
import FavoriteExperience from '@/components/FavoriteExperience.vue';
export default {
  name: 'ExperiencesView',
  components: {
    ModalView,
    FavoriteExperience
  },
  data() {
    return {
      experienceData:[],
      showModal:false,
      testaccess:this.$store.state,
      selectedExperiences:['001','002'],
      currentCondition:'002'
    }
  },
  created() {
 
},
methods: {
  openModal(data) {
        var test = this.$store.state.experiences.find( exp => exp.id === data);
        this.experienceData = test;
        this.showModal = true;
    },
  ShowSelectedModules(e) {
     
    var a = e.target.dataset.experiences;

    if(a != '') {
      console.log("a has something in it:",a);
      if(a.isArray) {
        console.log("a is an Array",a)
      } else {
        console.log("a is a string",a);

        if(a.indexOf(',') > -1) {
          console.log('a has a comma',a);
          this.selectedExperiences = a.split(',');
        } else {
          console.log('a is a single element',a);
          this.selectedExperiences = [ a ];
        }
        


      }
    } else {
      console.log("a is empty");
      this.selectedExperiences = [];
    }



   // this.selectedExperiences = 
   // console.log(this.selectedExperiences);
   console.log(this.selectedExperiences);
  },
},

  mounted() {
    if(!this.$store.state.loggedIn) {
        this.$router.push('/')
    }
    this.$root.$on('openModal',this.openModal);

  }
  ,
    computed: {
        shownFilteredConditions() {


 

          return this.$store.state.experiences.filter( item => this.selectedExperiences.indexOf(item.id) > -1)
             



          
        }
    }
}
</script>
<style lang="scss" >
  $buttoncolor: #55ade1;
  $buttonhovercolor:#4188b1;

.browse-experiences {
 h2 {
  font-weight:500;
  font-size:18px;
 }
}
.tempdiv {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}
.sec-specialties {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}
.sec-conditions {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}

.sec-medications {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}



.sec-procedures {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}

.sec-item {
  width:160px;
  font-size:14px;
  height:32px;
  border:1px solid $buttoncolor;
  background-color:   $buttoncolor;
  color:white;
  $buttonhovercolor:#4188b1;;
  border-radius:10px;
  margin:5px;
  line-height:32px;
  text-align:center;
}
</style>