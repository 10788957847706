<template>
    <div id="app">

      <header>
    <div class="logo" @click="gotohome" >
      <img alt="HouseCallVR logo" src="@/assets/housecallvr-logo.png" width="125" height="125" />
    </div>
    <div class="navtitle" >Admin</div>
    <nav >
      <span v-if="this.$store.state.loggedIn == true">
        <RouterLink to="/">Home</RouterLink>
      <RouterLink to="/about">About</RouterLink>
      <button class="signoutbutton" @click="signOut()">sign out</button>
      </span>
    </nav>
  </header>
  <div class="content">

    <RouterView />
  </div>
    </div>
  </template>
  
  
  
  
  
  
  <script>
  
  export default {
    components: {
  
  },
  data() {
  return {
    store:'test'
  }
  },
  created() {
   

  },
  methods: {
    signOut() {
this.$store.commit('logOutUser')
this.$store.commit('changeCurrentUser',null) 
this.$router.push('/')
    },
    gotohome() {
      console.log("clicked");
      this.$router.push('/');
        },
  }
  }
  </script>
  
  
  
  <style lang="scss">
  $buttoncolor: #55ade1;
  $buttonhovercolor:#4188b1;
  

 #app {

  }

  .signinbutton {
    border:1px solid transparent;
    background-color:$buttoncolor;
    color:white;
    width:75px;
    height:32px;
    border-radius:5px;
    &:hover {
      background-color:$buttonhovercolor;
    }
  }


  .signoutbutton {
    border:1px solid transparent;
    background-color:$buttoncolor;
    color:white;
    width:75px;
    height:32px;
    border-radius:5px;
    &:hover {
      background-color:$buttonhovercolor;
    }
  }
  </style>
  