import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn:false,
    currentUser:null,
    users:[
      {'id':'001','name':'','title':'Adminstrator'}
    ],
    favorites:['010','011','012','013','014'],
    patients:[
      {'id':'001','lastname':'martin','firstname':'bob','dateofbirth':'03/13/1963','preferred_language':'english'},
      {'id':'002','lastname':'hernandez','firstname':'maria','dateofbirth':'05/20/1963','preferred_language':'spanish'},
      {'id':'003','lastname':'zacharie','firstname':'lucas','dateofbirth':'10/04/1963','preferred_language':'french'},
      {'id':'004','lastname':'kay','firstname':'linus','dateofbirth':'07/28/1963','preferred_language':'german'},
      {'id':'005','lastname':'price','firstname':'sarah','dateofbirth':'08/03/1963','preferred_language':'english'},
    ],
    patientexperiences:[
      {'id':'3424','userid':'001','experienceid':'001','dateexperienced':'02/20/2024','score':'2/3'},
      {'id':'2453','userid':'001','experienceid':'002','dateexperienced':'02/20/2024','score':'3/3'},
      {'id':'5783','userid':'002','experienceid':'001','dateexperienced':'02/20/2024','score':'2/3'},
      {'id':'6734','userid':'002','experienceid':'002','dateexperienced':'02/20/2024','score':'3/3'}
    ],
    experiences: [
      {'id':'001','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Introduction','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-diabetes.png)','description':'Diabetes Introduction and Overview: HouseCall VR’s module immerses patients in a virtual journey through the body, visually illustrating the effects of diabetes and its impact on overall health. This engaging experience provides a comprehensive overview, making complex information accessible and memorable.'},
      {'id':'002','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Emotional Balance','duration': '6 min','rating': '4/5','imgurl':'background-image:url(exp-diabetes-healthy-coping.png)','description':'Diabetes Healthy Coping: Navigate the emotional landscape of living with diabetes in HouseCall VR’s supportive environment, where virtual scenarios teach effective stress management and coping strategies, fostering resilience and emotional well-being.'},
      {'id':'003','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Eating Well','duration': '4 min','rating': '5/5','imgurl':'background-image:url(exp-diabetes-healthy-eating.png)','description':'Diabetes Healthy Eating: Step into a virtual kitchen with HouseCall VR, where interactive cooking sessions and grocery store tours make learning about diabetic-friendly nutrition both fun and practical, encouraging healthy food choices in everyday life.'},
      {'id':'004','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Active Living','duration': '3 min','rating': '4/5','imgurl':'background-image:url(exp-diabetes-being-active.png)','description':'Diabetes Being Active: Experience the joy of movement in HouseCall VR’s dynamic module, which transports patients to various engaging environments to promote physical activity, demonstrating exercise’s critical role in managing diabetes in an interactive and motivating way.'},
      {'id':'005','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Daily Doses','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-diabetes-taking-medication.png)','description':'Diabetes Taking Medications: HouseCall VR demystifies the process of managing diabetes medications through immersive tutorials and simulations, ensuring patients understand the importance of adherence and how to effectively manage their treatment regimen.'},
      {'id':'006','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Check It','duration': '6 min','rating': '4/5','imgurl':'background-image:url(exp-diabetes-monitoring.png)','description':'Diabetes Monitoring: Engage with HouseCall VR’s hands-on module that simulates real-life blood glucose monitoring and interpretation, empowering patients with the knowledge and confidence to take control of their diabetes management.'},
      {'id':'007','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Be Safe','duration': '4 min','rating': '5/5','imgurl':'background-image:url(exp-diabetes-reducing-risk.png)','description':'Diabetes Reducing Risk: Venture into a virtual world with HouseCall VR that highlights the risks associated with diabetes and provides interactive education on preventive measures, emphasizing the importance of proactive health management.'},
      {'id':'008','category':'001', 'specialites':'nutrition','modulename':'diabetes_10001','title':'Diabetes<br/>Smart Solutions','duration': '3 min','rating': '4/5','imgurl':'background-image:url(exp-diabetes-problem-solving.png)','description':'Diabetes Problem Solving: Solve diabetes-related challenges in real-time with HouseCall VR’s interactive problem-solving module, designed to equip patients with practical skills and strategies to navigate daily management and unexpected situations with confidence.'},


      {'id':'010','category':'005', 'specialites':'Plastic Surgery','modulename':'lamina_10002','title':'LaMiNa:The Next Generation<br/>of Facial Rejuvenation','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-plastic-surgery-lamina.png)','description':'This VR module explores the groundbreaking LaMiNa procedure, a minimally invasive technique designed to lift and tighten the skin without extensive surgery. The module will offer a deep dive into how LaMiNa employs three well tested facial rejuvenation techniques, combined to activate natural collagen production, resulting in a smoother, more youthful complexion. Participants can interactively experience the procedure from INSIDE the skin, highlighting the procedure\'s benefits and innovative aspects.'},
      {'id':'011','category':'005','modulename':'module_lamina_10002','title':'Deep Plane Facelift: <br/>Beyond the Surface','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-plastic-surgery-deep-plane-facelift.png)','description':'This module provides an immersive exploration of the deep plane facelift, a sophisticated technique that adjusts deeper facial tissues to achieve more natural and long-lasting results compared to traditional methods. The VR experience will detail how the deep plane approach carefully repositions the muscles and fat beneath the skin, emphasizing the technique’s effectiveness in addressing severe facial sagging and deep wrinkles. Users can visualize the surgical process and expected outcomes, enhancing their understanding of why this method is often preferred for more significant rejuvenation.'},
      {'id':'012','category':'005','modulename':'module_lamina_10002','title':'Art of Rhinoplasty:  <br/>Reshaping and Refining','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-plastic-surgery-rhinoplasty.png)','description':'This module could delve into the intricacies of nose reshaping, offering a detailed view of the surgical techniques and the subtle artistry involved in achieving a balanced and natural-looking outcome.'},
      {'id':'013','category':'005','modulename':'module_lamina_10002','title':'Contour Mastery:<br/>High-Definition Liposculpture','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-plastic-surgery-liposuction.png)','description':'Focus on advanced liposuction techniques that sculpt and define the body with precision. This module could include interactive elements that show how surgeons target specific areas to enhance muscular definition and reduce unwanted fat.'},
      {'id':'014','category':'005','modulename':'module_lamina_10002','title':'Revolutionary Rejuvenation:<br/>Stem Cell Facelifts Unveiled','duration': '5 min','rating': '5/5','imgurl':'background-image:url(exp-plastic-surgery-stem-cell-facelift.png)','description':'Focus on advanced liposuction techniques that sculpt and define the body with precision. This module could include interactive elements that show how surgeons target specific areas to enhance muscular definition and reduce unwanted fat.'},


    ],


    specialties: [
      {'id':'001','title':'Family Medicine','experiences':['001','002','003','004','005','006','007','008'],'imgurl':'/icons/specialty-familymedicine.png'},
        {'id':'002','title':'Pediatrics','experiences':['001','002','003','004','005','006','007','008'],'imgurl':'/icons/specialty-pediatrics.png'},
        {'id':'003','title':'Podiatry','experiences':['001','002','003','004','005','006','007','008'],'imgurl':'/icons/specialty-podiatry.png'},
        {'id':'004','title':'Nutrition','experiences':['001','002','003','004','005','006','007','008'],'imgurl':'/icons/specialty-nutrition.png'},   
        {'id':'005','title':'Plastic Surgery','experiences':['010','011','012','013','014'],'imgurl':'/icons/specialty-plasticsurgery.png'},  
        {'id':'006','title':'Cardiology','experiences':[],'imgurl':'/icons/specialty-cardiology.png'},  
        {'id':'007','title':'Gynecology','experiences':[],'imgurl':'/icons/specialty-gynecology.png'},  
        {'id':'008','title':'Edocrinology','experiences':[],'imgurl':'/icons/specialty-edocrinology.png'},  
      ],
      sections: [
        {'id':'001','title':'Specialties','keyword':'specialties'},
        {'id':'002','title':'Conditions','keyword':'conditions'},
        {'id':'003','title':'Medications','keyword':'medications'},
        {'id':'004','title':'Procedures','keyword':'procedures'},
      ],
      procedures: [
        {'id':'001','title':'Facial Rejuvenation','experiences':['014'],'imgurl':'/icons/procedure-facialrejuvenation.png'},
          {'id':'002','title':'Appendectomy','experiences':[],'imgurl':'/icons/procedure-appendectomy.png'},
          {'id':'003','title':'Breast biopsy','experiences':[],'imgurl':'/icons/procedure-breastbiopsy.png'},
          {'id':'004','title':'Carotid endarterectomy','experiences':[],'imgurl':'/icons/procedure-cartoidendarterectomy.png'},   
          {'id':'005','title':'Cataract surgery','experiences':[],'imgurl':'/icons/procedure-cataractsurgery.png'},  
          {'id':'006','title':'Cesarean Section','experiences':[],'imgurl':'/icons/procedure-cesareansection.png'},  
          {'id':'007','title':'Cholecystectomy','experiences':[],'imgurl':'/icons/procedure-cholecystectomy.png'},  
          {'id':'008','title':'Radiation Oncology','experiences':[],'imgurl':'/icons/procedure-radiationoncology.png'},  
        ],
        conditions: [
          {'id':'001','title':'Diabetes','experiences':['001','002','003','004','005','006','007','008'],'imgurl':'/icons/procedure-facialrejuvenation.png'},
            {'id':'002','title':'Cancer','experiences':[],'imgurl':'/icons/procedure-appendectomy.png'},
            {'id':'003','title':'Hypertension','experiences':[],'imgurl':'/icons/procedure-breastbiopsy.png'},
            {'id':'004','title':'Obesity','experiences':[],'imgurl':'/icons/procedure-cartoidendarterectomy.png'},   
            {'id':'005','title':'Alzheimers','experiences':[],'imgurl':'/icons/procedure-cataractsurgery.png'},
          ],
          medications: [
            {'id':'001','title':'Diabetes','experiences':['005'],'imgurl':'/icons/procedure-facialrejuvenation.png'},
              {'id':'002','title':'Hypertension','experiences':[],'imgurl':'/icons/procedure-appendectomy.png'},
              {'id':'003','title':'Cancer','experiences':[],'imgurl':'/icons/procedure-breastbiopsy.png'},
              {'id':'004','title':'Cholesterol','experiences':[],'imgurl':'/icons/procedure-cartoidendarterectomy.png'},   
              {'id':'005','title':'Infections/Antibiotics','experiences':[],'imgurl':'/icons/procedure-cataractsurgery.png'},
            ],
    categories: [
        {'id':'001','title':'Cardiology','imgurl':'/icons/cat-cardiology.png'},
          {'id':'002','title':'Diabetes','imgurl':'/icons/cat-diabetes.png'},
          {'id':'003','title':'Facial Plastic Surgery','imgurl':'/icons/cat-facial-plastic-surgery.png'},
          {'id':'004','title':'Gastroenterology','imgurl':'/icons/cat-gastroenterology.png'},   
          {'id':'005','title':'Gynocology','imgurl':'/icons/cat-gynocology.png'},  
          {'id':'006','title':'Orthopedics','imgurl':'/icons/cat-ortho.png'},  
          {'id':'007','title':'Plastic Surgery','imgurl':'/icons/cat-plastic-surgery.png'},  
        ],
        teachbackdata: [
          {'id':'001','name':'Bob','recorded_on': 'Feb 19, 2024','recording_url': '/audio/0002.mp3', 'notes':''}
  
        ],
  },
  getters: {
  },
  mutations: {
    addFavorite(state,item) {
      state.favorites.push(item);
      state.favorites.sort();
    },
    removeFavorite(state,item) {
      state.favorites.splice(item,1);
      state.favorites.sort();
    },
    addPatient(state,item) {
      state.patients.push(item);
    },
    addExperience(state,item) {
      state.patientexperiences.push(item);
    },

    logInUser(state) {
        state.loggedIn = true;
    },
    logOutUser(state) {
        state.loggedIn = false;
    },
    changeCurrentUser(state,payload) {
        state.currentUser = payload;
    }
  },
  actions: {
    addFavorite({commit},item) {
      commit("addFavorite",item);
    },
    removeFavorite({commit},item) {
      commit("removeFavorite",item);
    },
    addPatient({commit},item) {
      commit("addPatient",item);
    },
    addExperience({commit},item) {
      commit("addExperience",item);
    }
  },
  modules: {
  }
})
