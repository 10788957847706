<template>
  <div class="home">
  <div class="welcome"> Welcome, {{this.$store.state.currentUser}}</div>


    <modal-view v-if="showModal" @close="showModal = false" :modaldata="experienceData"/>
    <AddPatientModal v-if="showAddPatientModal" @close="showAddPatientModal = false" :modaldata="experienceData"/>
    <AddExperienceModal v-if="showAddExperienceModal" @close="showAddExperienceModal = false" :patientdata="selectedPatient"/>
    <ViewTeachBackModal v-if="showTeachBackModal" @close="showTeachBackModal = false" />
    <showQRModal v-if="showQR" @close="showQR = false"/>
    <div>
      <h1>Favorites</h1>

    </div>


    <div class="fav-exp-container-wrapper">
      <div class="fav-exp-container">
        <FavoriteExperience v-for="experience in favoriteExperiences" :key="experience.id" :expdata="experience">
      
        </FavoriteExperience>
      </div>
      <button class="app-button" @click="$router.push('/experiences')">All Experiences →</button>
    </div>

    <!-- <h1>Categories</h1>
    <div class="categories-container-wrapper">
        <div class="categories-container">
            <CategoryItem v-for="cat in this.$store.state.categories" :key="cat.id" :catdata="cat"/>
        </div>
    </div> -->

<div class="section1">


  <div>
    <div class="patientsnav">
      <div style="display:flex">
        <h1>Patients</h1>
      <button @click="addPatient" class="addpatient">➕ Add Patient</button>
      </div>

      <div>Search:<input type="text" v-model="patientsearchterm"></div>
    </div>
      <div class="patientlist">
        <PatientList v-for="patient in this.patientListSearch" :key="patient.id" :patientdata="patient"/>
      </div>

    </div>

    <!-- <div class="tb-modules">
      <h3>Teachback</h3>
      <TeachBackModule v-for="tb in this.$store.state.teachbackdata" :key="tb.id" :teachbackdata="tb"/>
    </div> -->
  </div>


  </div>
</template>

<script>
// @ is an alias to /src
//import CategoryItem from '@/components/CategoryItem.vue';
import FavoriteExperience from '@/components/FavoriteExperience.vue'
import ModalView from '@/components/ModalView.vue';
//import TeachBackModule from '@/components/TeachBackModule.vue'
import PatientList from '@/components/PatientList.vue';
import AddPatientModal from '@/components/AddPatientModal.vue'
import AddExperienceModal from '@/components/AddExperienceModal.vue'
import ViewTeachBackModal from '@/components/ViewTeachBackModal.vue'
import showQRModal from '@/components/showQRModal.vue';
export default {
  name: 'HomeView',
  components: {
  //  CategoryItem,
    FavoriteExperience,
    ModalView,
    AddPatientModal,
    AddExperienceModal,
    ViewTeachBackModal,
    PatientList,
    showQRModal
  },
  data() {
    return {
        experienceData:[],
        selectedPatient:null,
      test:true,
      showModal:false,
      showQR:false,
      showAddPatientModal:false,
      showAddExperienceModal:false,
      showTeachBackModal:false,
      patientsearchterm:''
 
    }
  },
  created() {
 
},
methods: {
  addPatient() {

    this.showAddPatientModal = true;
  },
    openModal(data) {
        var test = this.$store.state.experiences.find( exp => exp.id === data);
        this.experienceData = test;
        this.showModal = true;
    },
    addExperience(data) {
      console.log('a:',data);
      this.selectedPatient = this.$store.state.patients.find( patient => patient.id === data);
      console.log(this.selectedPatient);
 
      // console.log(this.selectedPatient);
 this.showAddExperienceModal = true;
    },
    showTeachBack(data) {
      this.showTeachBackModal = true;
      console.log(data);
    },
showMeQR() {
  console.log("fe-showQR2")
  this.showQR = true;

},
},

  mounted() {
    console.log(this.$store.state.favorites);
    console.log(this.favoriteExperiences);
    if(!this.$store.state.loggedIn) {
        this.$router.push('/')
    }


 this.$root.$on('openModal',this.openModal);
 this.$root.$on('addExperience',this.addExperience);
 this.$root.$on('showTeachBack',this.showTeachBack);
 this.$root.$on('showQR',this.showMeQR);
  },
    computed: {
      favoriteExperiences() {
        return this.$store.state.experiences.filter(item => this.$store.state.favorites.indexOf(item.id) === -1);
      },
        patientListSearch() {

          if(this.patientsearchterm  != '') {
            return this.$store.state.patients.filter( patient => patient.lastname.includes(this.patientsearchterm))
          }
          else return this.$store.state.patients;


          
        }
    },
    watch: {
      favoriteExperiences(newvalue) {
        console.log(newvalue);
      }
    }
}
</script>
<style lang="scss" >
  $buttoncolor: #55ade1;
  $buttonhovercolor:#4188b1;


.app-button {
  background-color:$buttoncolor;
  border:0px solid transparent;
  color:white;
  border-radius:5px;
  padding:10px 20px 10px 20px;
  cursor:pointer;
  &:hover{
    background-color:$buttonhovercolor;
  }
}

.home {
  padding:20px;
}
.patientsnav {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  padding:10px;
}
.addpatient {
  width:120px;
  padding:0px;
  margin:5px 0px 5px 20px;
  background-color:$buttoncolor;
  color:white;
  border:0px;
  border-radius:5px;
  &:hover {
    background-color: $buttonhovercolor;
  }
}
.section1 {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}
.patientlist {
 width:700px;

}


.welcome {
    text-align:right;
    font-size:18px;
    font-weight:500;
    margin-bottom:20px;
}
.fav-exp-container-wrapper {
//overflow-x:scroll;

}
.fav-exp-container {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
 
  &::-webkit-scrollbar {
    display:none;
  }
}


.categories-container-wrapper {
  overflow-x:scroll;
}

.categories-container {
  display:flex;
  flex-direction:row;
  &::-webkit-scrollbar {
    display:none;
  }
}
.tb-modules {
  max-width:320px;
  border:1px solid #e1e1e1;
  padding:5px;
  border-radius:5px;
  box-shadow:5px 5px 5px rgba(114, 114, 114, 0.25);
  margin:10px 0px 0px 0px;
  h3 {
    font-weight:500;
    margin-bottom:10px;
    border-bottom:1px solid #e1e1e1;
  }
}
</style>