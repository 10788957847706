<template>
<div class="teachback-item" >
    <div class="tb-name"><span class="tb-label">Name:</span> {{teachbackdata.name}}</div>
    <div class="tb-date"><span class="tb-label">Recorded on:</span> {{teachbackdata.recorded_on}}</div>
    <div class="tb-notes"><span class="tb-label">Notes:</span> {{teachbackdata.notes}}</div>
    <div class="tb-audio">
        <audio controls>
        <source :src="teachbackdata.recording_url" type="audio/mpeg">
    </audio>
    </div>

</div>
</template>

<script>

export default {
  // Properties returned from data() become reactive state
  // and will be exposed on `this`.
  data() {
    return {

    }
  },
  props: ['teachbackdata'],
  // Methods are functions that mutate state and trigger updates.
  // They can be bound as event handlers in templates.
  methods: {

    openCustomizeExperience(e) {
        console.log('e:',e);
      //var exp = e.currentTarget.dataset.exp;
      //emitter.emit('event-name',exp)
    }

  },

  // Lifecycle hooks are called at different stages
  // of a component's lifecycle.
  // This function will be called when the component is mounted.
  mounted() {
  },

  created() {

},
}

</script>

<style scoped lang="scss">
.teachback-item {
    border-bottom:1px solid #e1e1e1;
    padding:10px 0px 10px 0px;
    margin-bottom:10px;
 
}
.tb-label {
    font-weight:700;
}
.tb-audio {
    margin-top:10px;
}
</style>