

<template>
<div class="fav-exp" :style="expdata.imgurl">
  <div class="favit" :data-id="expdata.id" @click="addToFavorites">🤍</div>
  <div class="exp-title" v-html="expdata.title">

  </div>
 <div class="exp-action">
    <button v-if="this.$store.state.currentUser === 'Admin' ||this.$store.state.currentUser === 'HealthcareProvider' " @click="openCustomizeExperience" :data-exp="expdata.id">Customize</button>
    <button @click="showQR">Launch</button>
  </div>
</div>

</template>

<script>

export default {
  // Properties returned from data() become reactive state
  // and will be exposed on `this`.
  data() {
    return {

    }
  },
  props: ['expdata'],
  // Methods are functions that mutate state and trigger updates.
  // They can be bound as event handlers in templates.
  methods: {
    addToFavorites(e) {
      var exp = e.currentTarget.dataset.id;
      console.log(exp);

     if(this.$store.state.favorites.includes(exp))  {

      var index = this.$store.state.favorites.indexOf(exp);
      console.log( 'already in the array');
      this.$store.commit('removeFavorite',index);
      console.log(this.$store.state.favorites);

     }
     else {
      this.$store.commit('addFavorite',exp);
      console.log(this.$store.state.favorites);
     }




    },
    openCustomizeExperience(e) {
        //console.log(e)
 var exp = e.currentTarget.dataset.exp;
      //emitter.emit('event-name',exp)
      this.$root.$emit('openModal',exp)
    },
    showQR() {
      this.$root.$emit('showQR');
    }


  },

  // Lifecycle hooks are called at different stages
  // of a component's lifecycle.
  // This function will be called when the component is mounted.
  mounted() {
    


  },

  created() {
 

},
}

</script>


<style scoped>


.fav-exp {
  border:0px solid red;
  border-radius:10px;
  height:200px;
  width:200px;
  min-width:200px;
  background-size:200px 200px;
  margin:10px;
}


.favit {
  text-align:right;
  padding:5px;
}

.exp-title {
  background-color:rgba(0,0,0,0.5);
  color:white;
  position:relative;
  text-align:center;
  line-height:1.3em;
  top:75px;
}
.exp-action {
  text-align:center;
  position:relative;
  top:85px;

 button {
  background-color:#55ade1;
  margin:0px 10px;
  padding:5px 0px;
  border:0px;
  color:white;
  border-radius:5px;
  width:75px;
  &:hover {
    cursor:pointer;
    filter:brightness(1.1);
  }
 }

}

</style>
