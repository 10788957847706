<template>
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <button class="modal-default-button" @click="$emit('close')">Close</button>
                <div class="modal-title" v-html="modaldata.title"></div>
                
                
              </div>
              <div class="modal-body">
               <div>🕒 Duration: {{ modaldata.duration }}</div> 
               <div>⭐ Rating: {{ modaldata.rating }}</div> 
               <div>📖 Course Description: {{ modaldata.description }}</div> 
               <div>📄 Course Overview: {{ modaldata.overview }}</div> 
    
               <h2>Customize</h2>
               <div class="customization-item">
                <form @submit.prevent="updateJSON"> 
                  <input type="hidden" name="module" :value="modaldata.modulename">
                
                <label>Language</label>&nbsp;
                <select name="language" >
                  <option value="english">English</option>
                  <option value="spanish">Spanish</option>
                  <option value="technical">Technical</option>
                </select>
                <img class="small-qrcode" src="/housecallvr-qr.jpg">
                <button class="modal-default-button" >Launch</button>
              </form>
               </div>
              
              </div>
              <div class="modal-footer">
                
                <br/>
                <br/>
              </div>
            
            </div>
          
          </div>
           
        </div>
      </transition>
    </template>
    
    
    <script>
import axios from 'axios';
    export default {
      // Properties returned from data() become reactive state
      // and will be exposed on `this`.
      data() {
        return {
    
        }
      },
      props: ['modaldata'],
      // Methods are functions that mutate state and trigger updates.
      // They can be bound as event handlers in templates.
      methods: {
    updateJSON(e) {
      //console.log("updateJSON:", e.target.elements);

      var lang = e.target.elements.language.value;
      var module = e.target.elements.module.value;

      var json = {
        'language':lang,
        'module':module
      }

      axios
        .post('https://api.housecallvr.com/updatelanguage', json, {
          headers: {
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          //this.uploading = false;
        });


        axios
        .post('https://api.housecallvr.com/updatemodule', json, {
          headers: {
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          //this.uploading = false;
        });




 

    },
    updateLanguage(e) {

      var lang = e.target.value;
      console.log(lang);

      var bod = {
        'language':lang
      }

      axios
        .post('https://api.housecallvr.com/updatelanguage', bod, {
          headers: {
            'Content-Type': 'application/json'
          },
        })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          //this.uploading = false;
        });
    }
    
    
      },
    
      // Lifecycle hooks are called at different stages
      // of a component's lifecycle.
      // This function will be called when the component is mounted.
      mounted() {
        
      },
    
      created() {
     
    
    },
    }
    
    </script>
    
    
    <style lang="scss">
      
   .small-qrcode {
    display:block;
    width:150px;
    height:150px;
    margin:0 auto;
    
   } 
    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      display: table;
      transition: opacity 0.3s ease;
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    
    .modal-container {
      width: 500px;
      margin: 0px auto;
      padding: 10px 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      text-align:left;
    }
    
    .modal-header {
      font-size:12px;
    }
    
    
    .modal-header .modal-title {
      margin-top: 0;
      font-size:23px;
      font-weight:700;
    }
    
    
    
    
    .modal-body {
      font-size:16px;
      margin: 20px 0;
      line-height:2em;
    
    
    h2 {
      font-size:20px;
      font-weight:700;
      margin:0px 0px 0px 0px !important;
    }
    
    }
    
    .modal-footer {
      font-size:12px;
    min-height:50px;
    }
    
    
    .modal-default-button {
    float:right;
    background-color:#55ade1;
      margin:0px 10px;
      padding:5px 0px;
      border:0px;
      color:white;
      border-radius:5px;
      width:75px;
      &:hover {
        cursor:pointer;
        filter:brightness(1.1);
      }
    }
    
    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */
    
    .modal-enter {
      opacity: 0;
    }
    
    .modal-leave-active {
      opacity: 0;
    }
    
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    
    
    
    
    </style>